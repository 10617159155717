:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._gap-t-space-6{gap:var(--t-space-6);}
:root ._bg-lightest{background-color:var(--lightest);}
:root ._w-10037{width:100%;}
:root ._h-280px{height:280px;}
:root ._bxsh-0px2px4pxva26674076{box-shadow:0px 2px 4px var(--shadowColor);}
:root ._bg-light{background-color:var(--light);}