:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._jc-center{justify-content:center;}
:root ._mih-16px{min-height:16px;}
:root ._miw-16px{min-width:16px;}
:root ._ai-center{align-items:center;}
:root ._btlr-100000px{border-top-left-radius:100000px;}
:root ._btrr-100000px{border-top-right-radius:100000px;}
:root ._bbrr-100000px{border-bottom-right-radius:100000px;}
:root ._bblr-100000px{border-bottom-left-radius:100000px;}
:root ._pt-0px{padding-top:0px;}
:root ._pr-0px{padding-right:0px;}
:root ._pb-0px{padding-bottom:0px;}
:root ._pl-0px{padding-left:0px;}
:root ._w-16px{width:16px;}
:root ._h-16px{height:16px;}
:root ._maw-16px{max-width:16px;}
:root ._mah-16px{max-height:16px;}
:root ._bg-errorDarker{background-color:var(--errorDarker);}
:root ._pe-none{pointer-events:none !important;}
:root ._als-flex-end{align-self:flex-end;}