:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._gap-t-space-3{gap:var(--t-space-3);}
:root ._fd-row{flex-direction:row;}
:root ._bg-errorDarker{background-color:var(--errorDarker);}
:root ._pt-t-space-5{padding-top:var(--t-space-5);}
:root ._pr-t-space-5{padding-right:var(--t-space-5);}
:root ._pb-t-space-5{padding-bottom:var(--t-space-5);}
:root ._pl-t-space-5{padding-left:var(--t-space-5);}
:root ._ai-center{align-items:center;}
:root ._zi-999{z-index:999;}
:root ._t-0px{top:0px;}
:root ._pos-fixed{position:fixed;}
:root ._w-10037{width:100%;}
:root ._fs-1{flex-shrink:1;}
:root ._fg-1{flex-grow:1;}