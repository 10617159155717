:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fd-row{flex-direction:row;}
:root ._fg-1{flex-grow:1;}
:root ._fs-1{flex-shrink:1;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._w-280px{width:280px;}
:root ._pt-10px{padding-top:10px;}
:root ._pr-10px{padding-right:10px;}
:root ._pb-10px{padding-bottom:10px;}
:root ._pl-10px{padding-left:10px;}
:root ._w-322px{width:322px;}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._h-84px{height:84px;}
:root ._w-310px{width:310px;}