:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._fg-1{flex-grow:1;}
:root ._pr-25px{padding-right:25px;}
:root ._pl-25px{padding-left:25px;}
:root ._btlr-10px{border-top-left-radius:10px;}
:root ._btrr-10px{border-top-right-radius:10px;}
:root ._bbrr-10px{border-bottom-right-radius:10px;}
:root ._bblr-10px{border-bottom-left-radius:10px;}
:root ._bg-lightest{background-color:var(--lightest);}
:root ._pt-10px{padding-top:10px;}
:root ._pb-10px{padding-bottom:10px;}
:root ._pt-30px{padding-top:30px;}
:root ._pb-30px{padding-bottom:30px;}
:root ._fs-1{flex-shrink:1;}