:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._fg-1{flex-grow:1;}
:root ._gap-15px{gap:15px;}
:root ._h-auto{height:auto;}
:root ._h-200px{height:200px;}
:root ._gap-8px{gap:8px;}
:root ._fd-row{flex-direction:row;}
:root ._ai-center{align-items:center;}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._btw-1px{border-top-width:1px;}
:root ._brw-1px{border-right-width:1px;}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._blw-1px{border-left-width:1px;}
:root ._btc-mediumLight{border-top-color:var(--mediumLight);}
:root ._brc-mediumLight{border-right-color:var(--mediumLight);}
:root ._bbc-mediumLight{border-bottom-color:var(--mediumLight);}
:root ._blc-mediumLight{border-left-color:var(--mediumLight);}
:root ._btlr-t-radius-4{border-top-left-radius:var(--t-radius-4);}
:root ._btrr-t-radius-4{border-top-right-radius:var(--t-radius-4);}
:root ._bbrr-t-radius-4{border-bottom-right-radius:var(--t-radius-4);}
:root ._bblr-t-radius-4{border-bottom-left-radius:var(--t-radius-4);}
:root ._pt-t-space-2{padding-top:var(--t-space-2);}
:root ._pr-t-space-2{padding-right:var(--t-space-2);}
:root ._pb-t-space-2{padding-bottom:var(--t-space-2);}
:root ._pl-t-space-2{padding-left:var(--t-space-2);}
:root ._gap-10px{gap:10px;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._jc-center{justify-content:center;}