:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._pt-t-space-2--53{padding-top:var(--t-space-2--5);}
:root ._pr-0px{padding-right:0px;}
:root ._pb-t-space-2--53{padding-bottom:var(--t-space-2--5);}
:root ._pl-t-space-2--53{padding-left:var(--t-space-2--5);}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._bg-secondaryLi98335648{background-color:var(--secondaryLighter);}
:root ._fd-column{flex-direction:column;}
:root ._gap-t-space-2--53{gap:var(--t-space-2--5);}
:root ._jc-center{justify-content:center;}
:root ._bg-lighter{background-color:var(--lighter);}